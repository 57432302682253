export default {
  state: {
    name: '',
    url: '',
    color: 'indigo',
    syncFrequency: 5,
    isLoading: false,
    alarms: [],
  },
};
