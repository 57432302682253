/* eslint-disable */
import {
  Button,
  Checkbox,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  Stack,
  Text,
} from '@chakra-ui/react';
import {
  CalendarSettingsResponse,
  PatchCalendarSettingsRequest,
} from '../../../bloben-interface/calendarSettings/calendarSettings';
import { CalendarView } from 'kalend';
import { ReduxState } from '../../../types/interface';
import {
  setCalendarSettings,
  setSettings,
  setThemeSettings,
} from '../../../redux/actions';
import { useDispatch, useSelector } from 'react-redux';
import CalendarSettingsApi from '../../../api/CalendarSettingsApi';
import React, { useContext, useEffect, useState } from 'react';
import SettingsRow from '../settingsRow/SettingsRow';
import ChakraTimezoneSelect from 'components/chakraCustom/ChakraTimezoneSelect';
import {
  DEFAULT_TIME_SETTINGS,
  THEME_SETTINGS,
  ThemeSettings,
} from '../../../redux/reducers/themeSettings';
import { capitalStart, handleIsDarkTheme } from '../../../utils/common';
import { Context } from '../../../context/store';
import ChakraInput from '../../../components/chakraCustom/ChakraInput';

const menuStyle: any = {
  width: '100%',
  justifyContent: 'flex-start',
  textAlign: 'left',
};

const GeneralSettings = () => {
  const [store, dispatchContext] = useContext(Context);
  const setContext = (type: string, payload: any) => {
    dispatchContext({ type, payload });
  };

  const dispatch = useDispatch();
  const settings: CalendarSettingsResponse = useSelector(
    (state: ReduxState) => state.calendarSettings
  );
  const themeSettings: ThemeSettings = useSelector(
    (state: ReduxState) => state.themeSettings
  );
  const [hourHeightValue, setHourHeightValue] = useState(settings.hourHeight);
  const [themeTimeSettings, setThemeTimeSettings] = useState(
    DEFAULT_TIME_SETTINGS
  );

  const requestUpdate = async (data: PatchCalendarSettingsRequest) => {
    await CalendarSettingsApi.patch(data);

    const response = await CalendarSettingsApi.get();

    dispatch(setCalendarSettings(response.data));
  };

  const handleUpdate = async (key: string, value: any) => {
    const newSettings: any = {};

    newSettings[key] = value;

    await requestUpdate(newSettings);
  };

  const handleTimezoneUpdate = async (item: any) => {
    const newSettings: any = {};

    newSettings.timezone = item.value;

    await requestUpdate(newSettings);
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const checkDisabledViewBox = (value: any) => {
    const newSettings: any = { ...settings };

    let disabledViews: string[] = newSettings.disabledViews;

    if (disabledViews.includes(value)) {
      disabledViews = disabledViews.filter((item) => item !== value);
    } else {
      disabledViews.push(value);
    }

    newSettings['disabledViews'] = disabledViews;

    dispatch(setSettings(newSettings));
  };

  const handleShowWeekNumbersChange = async () => {
    const newSettings: any = {};

    newSettings['showWeekNumbers'] = !settings.showWeekNumbers;

    await requestUpdate(newSettings);
  };

  useEffect(() => {
    setThemeTimeSettings(themeSettings.value);
  }, []);

  const handleUpdateThemeSettings = (
    settingsValue: THEME_SETTINGS,
    value?: any
  ) => {
    const newSettings = {
      settings: settingsValue,
      value: value ? value : DEFAULT_TIME_SETTINGS,
    };
    dispatch(setThemeSettings(newSettings));
  };

  const onChangeTimeSettingsTheme = (e: any) => {
    const name = e.target.name;
    const value = e.target.value;

    let newValue = { ...themeTimeSettings };
    if (name === 'hourFrom') {
      newValue.from.hour = value;
    } else if (name === 'minuteFrom') {
      newValue.from.minute = value;
    } else if (name === 'hourTo') {
      newValue.to.hour = value;
    } else if (name === 'minuteTo') {
      newValue.to.minute = value;
    }

    setThemeTimeSettings(newValue);
  };
  const onBlur = (e: any) => {
    const name = e.target.name;
    const value =
      e.target.value.length === 1 ? `0${e.target.value}` : e.target.value;

    let newValue = { ...themeTimeSettings };

    if (name === 'hourFrom') {
      newValue.from.hour = value;
    } else if (name === 'minuteFrom') {
      newValue.from.minute = value;
    } else if (name === 'hourTo') {
      newValue.to.hour = value;
    } else if (name === 'minuteTo') {
      newValue.to.minute = value;
    }

    setThemeTimeSettings(newValue);

    handleUpdateThemeSettings(THEME_SETTINGS.TIME, newValue);
  };

  return (
    <>
      <SettingsRow title={'Timezone'}>
        <ChakraTimezoneSelect
          onSelect={handleTimezoneUpdate}
          value={settings.timezone}
        />
      </SettingsRow>
      <SettingsRow title={'Time format'}>
        <Menu>
          <MenuButton
            as={Button}
            style={menuStyle}
            _focus={{ boxShadow: 'none' }}
          >
            {settings.timeFormat}
          </MenuButton>
          <MenuList>
            <MenuItem
              minH="40px"
              onClick={() => handleUpdate('timeFormat', '24')}
            >
              <span>24</span>
            </MenuItem>
            <MenuItem
              minH="40px"
              onClick={() => handleUpdate('timeFormat', '12')}
            >
              <span>12</span>
            </MenuItem>
          </MenuList>
        </Menu>
      </SettingsRow>
      <SettingsRow title={'Start of week'}>
        <Menu>
          <MenuButton
            as={Button}
            style={menuStyle}
            _focus={{ boxShadow: 'none' }}
          >
            {settings.startOfWeek}
          </MenuButton>
          <MenuList>
            <MenuItem
              minH="40px"
              onClick={() => handleUpdate('startOfWeek', 'Monday')}
            >
              <span>Monday</span>
            </MenuItem>
            <MenuItem
              minH="40px"
              onClick={() => handleUpdate('startOfWeek', 'Sunday')}
            >
              <span>Sunday</span>
            </MenuItem>
          </MenuList>
        </Menu>
      </SettingsRow>
      <SettingsRow title={'Default view'}>
        <Menu>
          <MenuButton
            as={Button}
            style={menuStyle}
            _focus={{ boxShadow: 'none' }}
          >
            {settings.defaultView}
          </MenuButton>
          <MenuList>
            <MenuItem
              minH="40px"
              onClick={() => handleUpdate('defaultView', CalendarView.AGENDA)}
            >
              <span>Agenda</span>
            </MenuItem>
            <MenuItem
              minH="40px"
              onClick={() => handleUpdate('defaultView', CalendarView.DAY)}
            >
              <span>Day</span>
            </MenuItem>
            <MenuItem
              minH="40px"
              onClick={() =>
                handleUpdate('defaultView', CalendarView.THREE_DAYS)
              }
            >
              <span>3 days</span>
            </MenuItem>
            <MenuItem
              minH="40px"
              onClick={() => handleUpdate('defaultView', CalendarView.WEEK)}
            >
              <span>Week</span>
            </MenuItem>
            <MenuItem
              minH="40px"
              onClick={() => handleUpdate('defaultView', CalendarView.MONTH)}
            >
              <span>Month</span>
            </MenuItem>
          </MenuList>
        </Menu>
      </SettingsRow>
      <SettingsRow title={'Hour height'}>
        <NumberInput
          style={menuStyle}
          defaultValue={settings.hourHeight}
          max={120}
          min={20}
          step={5}
          width={40}
          keepWithinRange={true}
          clampValueOnBlur={false}
          onChange={(valueAsString: string, valueAsNumber: number) => {
            setHourHeightValue(valueAsNumber);
          }}
          onBlur={() => handleUpdate('hourHeight', hourHeightValue)}
        >
          <NumberInputField />
          <NumberInputStepper>
            <NumberIncrementStepper />
            <NumberDecrementStepper />
          </NumberInputStepper>
        </NumberInput>
      </SettingsRow>

      <SettingsRow title={'Show week numbers'}>
        <Button
          variant={'ghost'}
          onClick={handleShowWeekNumbersChange}
          _focus={{ boxShadow: 'none' }}
        >
          <Checkbox
            isChecked={settings.showWeekNumbers}
            onChange={handleShowWeekNumbersChange}
            size={'lg'}
          ></Checkbox>
        </Button>
      </SettingsRow>
      <SettingsRow title={'Theme settings'}>
        <Menu>
          <MenuButton
            as={Button}
            style={menuStyle}
            _focus={{ boxShadow: 'none' }}
          >
            {capitalStart(themeSettings.settings)}
          </MenuButton>
          <MenuList>
            <MenuItem
              minH="40px"
              onClick={() => handleUpdateThemeSettings(THEME_SETTINGS.LIGHT)}
            >
              <span>Light</span>
            </MenuItem>
            <MenuItem
              minH="40px"
              onClick={() => handleUpdateThemeSettings(THEME_SETTINGS.DARK)}
            >
              <span>Dark</span>
            </MenuItem>
            <MenuItem
              minH="40px"
              onClick={() => handleUpdateThemeSettings(THEME_SETTINGS.TIME)}
            >
              <span>Time</span>
            </MenuItem>
          </MenuList>
        </Menu>
      </SettingsRow>
      {themeSettings.settings === THEME_SETTINGS.TIME ? (
        <SettingsRow title={'Light theme range'}>
          <Stack direction={'row'} spacing={1} style={{ alignItems: 'center' }}>
            <ChakraInput
              value={themeTimeSettings.from.hour}
              maxLength={2}
              name={'hourFrom'}
              onChange={onChangeTimeSettingsTheme}
              width={14}
              type={'numeric'}
              onBlur={onBlur}
            />
            <Text>:</Text>
            <ChakraInput
              value={themeTimeSettings.from.minute}
              maxLength={2}
              name={'minuteFrom'}
              onChange={onChangeTimeSettingsTheme}
              width={14}
              type={'numeric'}
              onBlur={onBlur}
            />
            <Text> - </Text>
            <ChakraInput
              value={themeTimeSettings.to.hour}
              maxLength={2}
              name={'hourTo'}
              onChange={onChangeTimeSettingsTheme}
              width={14}
              type={'numeric'}
              onBlur={onBlur}
            />
            <Text>:</Text>
            <ChakraInput
              value={themeTimeSettings.to.minute}
              maxLength={2}
              name={'minuteTo'}
              onChange={onChangeTimeSettingsTheme}
              width={14}
              type={'numeric'}
              onBlur={onBlur}
            />
          </Stack>
        </SettingsRow>
      ) : null}
      {/*<SettingsRow title={'Enabled views'}>*/}
      {/*  <Menu closeOnSelect={false}>*/}
      {/*    <MenuButton*/}
      {/*      as={Button}*/}
      {/*      style={menuStyle}*/}
      {/*      _focus={{ boxShadow: 'none' }}*/}
      {/*    >*/}
      {/*      Select*/}
      {/*    </MenuButton>*/}
      {/*    <MenuList>*/}
      {/*      <Stack spacing={1}>*/}
      {/*        <MenuItem*/}
      {/*          onClick={() => checkDisabledViewBox(CalendarView.AGENDA)}*/}
      {/*        >*/}
      {/*          <Box>*/}
      {/*            <Checkbox*/}
      {/*              colorScheme="teal"*/}
      {/*              value={CalendarView.AGENDA}*/}
      {/*              isChecked={*/}
      {/*                !settings.disabledViews.includes(CalendarView.AGENDA)*/}
      {/*              }*/}
      {/*            >*/}
      {/*              Agenda*/}
      {/*            </Checkbox>*/}
      {/*          </Box>*/}
      {/*        </MenuItem>*/}
      {/*        <MenuItem onClick={() => checkDisabledViewBox(CalendarView.DAY)}>*/}
      {/*          <Box>*/}
      {/*            <Checkbox*/}
      {/*              colorScheme="teal"*/}
      {/*              value={CalendarView.DAY}*/}
      {/*              isChecked={*/}
      {/*                !settings.disabledViews.includes(CalendarView.DAY)*/}
      {/*              }*/}
      {/*            >*/}
      {/*              Day*/}
      {/*            </Checkbox>*/}
      {/*          </Box>*/}
      {/*        </MenuItem>*/}
      {/*        <MenuItem*/}
      {/*          onClick={() => checkDisabledViewBox(CalendarView.THREE_DAYS)}*/}
      {/*        >*/}
      {/*          <Box>*/}
      {/*            <Checkbox*/}
      {/*              colorScheme="teal"*/}
      {/*              value={CalendarView.DAY}*/}
      {/*              isChecked={*/}
      {/*                !settings.disabledViews.includes(CalendarView.THREE_DAYS)*/}
      {/*              }*/}
      {/*            >*/}
      {/*              3 Days*/}
      {/*            </Checkbox>*/}
      {/*          </Box>*/}
      {/*        </MenuItem>*/}
      {/*        <MenuItem onClick={() => checkDisabledViewBox(CalendarView.WEEK)}>*/}
      {/*          <Box>*/}
      {/*            <Checkbox*/}
      {/*              colorScheme="teal"*/}
      {/*              value={CalendarView.DAY}*/}
      {/*              isChecked={*/}
      {/*                !settings.disabledViews.includes(CalendarView.WEEK)*/}
      {/*              }*/}
      {/*            >*/}
      {/*              Week*/}
      {/*            </Checkbox>*/}
      {/*          </Box>*/}
      {/*        </MenuItem>*/}
      {/*        <MenuItem*/}
      {/*          onClick={() => checkDisabledViewBox(CalendarView.MONTH)}*/}
      {/*        >*/}
      {/*          <Box>*/}
      {/*            <Checkbox*/}
      {/*              colorScheme="teal"*/}
      {/*              value={CalendarView.DAY}*/}
      {/*              isChecked={*/}
      {/*                !settings.disabledViews.includes(CalendarView.MONTH)*/}
      {/*              }*/}
      {/*            >*/}
      {/*              Month*/}
      {/*            </Checkbox>*/}
      {/*          </Box>*/}
      {/*        </MenuItem>*/}
      {/*      </Stack>*/}
      {/*    </MenuList>*/}
      {/*  </Menu>*/}
      {/*</SettingsRow>*/}
    </>
  );
};

export default GeneralSettings;
