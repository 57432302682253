export default {
  state: {
    name: '',
    url: '',
    color: 'indigo',
    alarms: [],
    components: ['VEVENT'],
    isLoading: false,
  },
};
